import {SideMenu} from "../SideMenu";
import {PrimarySearchAppBar} from "../PrimarySearchAppBar";
import * as React from "react";
import {IRestrictedPageTypes} from "./types";
import {Required} from "../Required/Required";

export const RestrictedPage = (props: IRestrictedPageTypes) => {
  return (
    <Required>
      <div className="app">
          {/*
        <SideMenu/>
           */}

        <div className="app-content">
          <PrimarySearchAppBar/>

          {props.children}
        </div>
      </div>
    </Required>
  );
}