import React from "react";
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import "./side-menu.scss";
import {useNavigate} from "react-router-dom";
import EventIcon from '@mui/icons-material/Event';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const SideMenu = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("dstinker-user"));

  const getFirstLetters = (name: string): string => {
    const firstLetters = name
      .split(' ')
      .map(word => word[0])
      .join('');

    return firstLetters;
  }

  return (
    <div className="side-menu--wrapper">
      <div className="menu-header">
        <div className="menu-header--credentials">
          <p>{getFirstLetters(currentUser.name)}</p>
        </div>
        <p>
          {currentUser.name}
        </p>
      </div>

      <List className="side-menu" sx={{width: 360}}>
        <ListItem
          onClick={() => {
            navigate("/");
          }}
          className={location.pathname === "/" ? "side-menu--item-active" : ""}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
          </ListItemButton>
        </ListItem>

        <ListItem
          className={location.pathname === "/event" ? "side-menu--item-active" : ""}
          onClick={() => {
            navigate("/event");
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <EventIcon/>
            </ListItemIcon>
            <ListItemText primary="Ture"/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}