import React from "react"
import {Backdrop, Button, CircularProgress, TextField} from "@mui/material";
import {APIContext} from "../../contexts";
import SendIcon from "@mui/icons-material/Send";
import "./login.scss"
import {AlertDialog} from "../../components";
import {useNavigate} from "react-router-dom";

export const Login = () => {
  const {login} = React.useContext(APIContext);
  const navigate = useNavigate();

  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  /**
   * When the user clicks login we encode the credentials and save them in localStorage for login.
   * If login fails we remove the credentials again.
   */
  const onLogin = () => {
    if (username && password) {
      setLoading(true);

      localStorage.setItem("Authorization", `Basic ${btoa(username + ':' + password)}`)

      login()
        .then((results) => {
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("dstinker-user", JSON.stringify(results[0]))

            setLoading(false);
          }, 1000)
        })
        .catch(() => {
          setTimeout(() => {
            localStorage.clear();
            setShowAlertDialog(true);
            setLoading(false);
          }, 1000)
        })
    }
  }

  return (
    <>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <AlertDialog
        title="Der skete en fejl"
        description="Der skete en fejl ved log ind. Prøv igen."
        onClose={() => {
          setShowAlertDialog(false)
        }}
        show={showAlertDialog}/>

      <div className="login">
        <div className="login--left">
          <div className="login--left-content">
            <TextField
              disabled={loading}
              autoFocus={true}
              className="login--input"
              label="Brugernavn"
              variant="outlined"
              type="email"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              value={username}/>
            <TextField
              disabled={loading}
              className="login--input"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              type={"password"}
              label="Kodeord"
              variant="outlined"
              value={password}/>

            <Button
              disabled={!username || !password || loading}
              size={"large"}
              onClick={onLogin}
              variant="contained">
              Log ind
            </Button>
          </div>
        </div>
        <div className="login--right">
        </div>
      </div>
    </>
  )
}