import React from "react";
import AddIcon from '@mui/icons-material/Add';
import {Card, Container, Fab, LinearProgress, Tab, Tabs} from "@mui/material";
import {DataTable, Header} from "../../components";
import {useNavigate} from "react-router-dom";
import {APIContext} from "../../contexts";
import {TripEvent} from "../../models/TripEvent";
import {DateTime} from "luxon";

export const Events = () => {
  const navigate = useNavigate();
  const {getEvents} = React.useContext(APIContext);
  const [events, setEvents] = React.useState<TripEvent[] | undefined>();
  const [sortedEvents, setSortedEvents] = React.useState<TripEvent[] | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);

  React.useEffect(() => {
    getEvents()
      .then(events => {
        setEvents(events);
        sortEvents(events, 0);

        setTimeout(() => {
          setLoading(false);
        }, 500)
      });
  }, [])

  const sortEvents = (events: TripEvent[] | undefined, sortIndex: number): void => {
    if (events) {
      const tempEvents: TripEvent[] = [];

      events.forEach(event => {
        const now = DateTime.now();
        const isNowOrFuture: boolean = event.endDate >= now.toMillis();
        const isTripAnnulled: boolean = (event.deletedAt !== null && event.deletedAt !== undefined && event.deletedAt > 0);

        if (sortIndex === 0 && isNowOrFuture && !isTripAnnulled) {
          tempEvents.push(event)
        } else if (sortIndex === 1 && !isNowOrFuture && !isTripAnnulled) {
          tempEvents.push(event)
        } else if (sortIndex === 2 && isTripAnnulled) {
          tempEvents.push(event)
        }
      });

      setSortedEvents(tempEvents)
    }
  }

  return (
    <>
      <Header
        title={"Turoversigt"}
        subTitle={"På denne side finder I en oversigt over alle jeres ture."}
      />

      <Container>
        <Card>
          <Tabs
            value={selectedTabIndex}
            onChange={(event, newIndex) => {
              setSelectedTabIndex(newIndex);
              sortEvents(events, newIndex);
            }}>
            <Tab label="Fremtidige ture" disabled={loading}/>
            <Tab label="Ældre ture" disabled={loading}/>
            <Tab label="Aflyste ture" disabled={loading}/>
          </Tabs>
        </Card>

        {!loading && <DataTable data={sortedEvents}/>}
        {loading && <LinearProgress style={{marginTop: "24px"}}/>}
      </Container>

      <Fab
        onClick={() => {
          navigate("/event/create")
        }}
        style={{position: "absolute", bottom: "48px", right: "48px"}} variant="extended"
        color="primary"
        aria-label="add">
        <AddIcon sx={{mr: 1}}/>
        Opret tur
      </Fab>
    </>
  );
}