import React from "react";
import {AddBookingDialog, EditBookingDialog, Header, LanguageSelector, SuccessDialog} from "../../../components";
import {
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    Fab,
    FormControlLabel,
    Grid,
    Paper,
    Snackbar,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import {APIContext} from "../../../contexts";
import {TripEvent} from "../../../models/TripEvent";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Booking} from "../../../models/Booking";
import "./edit-event.scss"
import Alert from '@mui/material/Alert';
import {EnhancedTableToolbar} from "../../../components/DataTable/DataTableToolbar/DataTableToolbar";
import {inPlaceSort} from "fast-sort";
import {Language} from "../../../models/Language";
import {EventForm} from "../Shared";
import {DateTime} from "luxon";

export const EditEvent = () => {
    const {
        getEvent,
        deleteEvent,
        updateEvent,
        getBookings,
        addBooking,
        deleteBooking,
        updateBooking,
        getPdf
    } = React.useContext(APIContext);
    let [params] = useSearchParams();
    let {id} = useParams();
    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = React.useState<Language>('da-DK');
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarText, setSnackbarText] = React.useState<string>("");
    const [deleteSuccess, setDeleteSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const [showFab, setShowFab] = React.useState<boolean>(true);
    const [dangerCheckboxChecked, setDangerCheckboxChecked] = React.useState<boolean>(false);
    const [event, setEvent] = React.useState<TripEvent | undefined>();
    const [showAddBookingDialog, setShowAddBookingDialog] = React.useState<boolean>(false)
    const [bookings, setBookings] = React.useState<Booking[]>([]);
    const [sortedBookings, setSortedBookings] = React.useState<any[]>([]);
    const [isAddingBookings, setIsAddingBookings] = React.useState<boolean>(false);
    const [editBooking, setEditBooking] = React.useState<Booking | null>(null);
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
    const [editLoading, setEditLoading] = React.useState<boolean>(false);
    const [initLoading, setInitLoading] = React.useState<boolean>(true);

    const totalBookedSeats: React.MutableRefObject<number> = React.useRef(0);

    React.useEffect(() => {
        if (id) {
            getCurrentEvent();
            getAllBookings();
        }
    }, [id])

    React.useEffect(() => {
        const addBookings = params.get("addBookings");
        if (addBookings) {
            setSelectedTab(1);
        }
    }, [params])

    const getCurrentEvent = (): void => {
        setInitLoading(true);

        if (id) {
            getEvent(Number(id)).then(event => {
                setTimeout(() => {
                    const tempEvent = {...event};
                    tempEvent.startDate = DateTime.fromMillis(event.startDate as number);
                    tempEvent.endDate = DateTime.fromMillis(event.endDate as number);
                    setEvent(tempEvent);
                    setInitLoading(false);
                }, 1000)
            })
        }
    }

    const getAllBookings = (): void => {
        if (id) {
            getBookings(id).then(bookings => {
                setBookings(bookings);

                let tempSortedBookings: any = {};
                bookings.forEach((booking: Booking) => {
                    if (!tempSortedBookings[booking.bookingId]) {
                        tempSortedBookings[booking.bookingId] = [];
                    }

                    tempSortedBookings[booking.bookingId].push(booking);

                    totalBookedSeats.current += booking.seats;

                    // We sort the user array on each push
                    inPlaceSort<Booking>(tempSortedBookings[booking.bookingId]).by([
                        {asc: u => u.firstName},
                    ]);
                })

                setSortedBookings(tempSortedBookings);

                setLoading(false);
            })
        }
    }

    const submitForm = async () => {
        if (event && event.startDate && event.endDate && isFormValid() && event) {
            const tempEvent: TripEvent = {...event};
            tempEvent.startDate = (event.startDate as DateTime).toMillis();
            tempEvent.endDate = (event.endDate as DateTime).toMillis();
            delete tempEvent.ID;
            delete tempEvent.createdBy;

            setLoading(true);

            try {
                if (id) {
                    await updateEvent(tempEvent, Number(id));
                    setShowSnackbar(true);
                    setSnackbarText("Ændringerne blev gemt")

                    setTimeout(() => {
                        setShowSnackbar(false);
                    }, 3000)
                }
            } catch (e) {
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 3000)
            }
        }
    }

    const isFormValid = (): boolean => {
        if (!event) {
            return false;
        }

        return event.title.length > 0 && event.description.length > 0 && event.seats > 0 && event.priceInEur > 0 && event.priceInDkk > 0;
    }

    const deleteCurrentEvent = (): void => {
        if (id && dangerCheckboxChecked) {
            deleteEvent(Number(id)).then(() => {
                setDeleteSuccess(true);
            }).catch(() => {
                // TODO: Show error if we cannot delete event
            })
        }
    }

    const addNewBooking = async (booking: Booking) => {
        setIsAddingBookings(true);

        await addBooking(booking)

        getCurrentEvent();
        getAllBookings();

        await new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000)
        })

        setIsAddingBookings(false);
    }

    const getPaidStatusClass = (booking: Booking): string => {
        if (event && event.priceInDkk > 0) {
            if ((booking.paidAmount / 100) === (event.priceInDkk * booking.seats)) {
                return "booking-paid";
            } else if (booking.paidAmount > 0) {
                return "booking-partially-paid";
            } else if (booking.paidAmount === 0) {
                return "booking-not-paid";
            }
        }

        return "";
    }

    return (
        <div className={"container"}>
            <Snackbar open={showSnackbar}>
                <Alert
                    elevation={6}
                    variant="filled"
                    severity="success"
                    sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>

            <SuccessDialog
                description={"Turen blev aflyst."}
                onRedirect={() => {
                    navigate("/event");
                }}
                show={deleteSuccess}/>

            <Header
                title="Redigér tur"
                actions={(
                    <LanguageSelector
                        language={selectedLanguage}
                        onChange={(language: Language) => {
                            setSelectedLanguage(language);
                        }}
                    />
                )}/>

            <Container>
                <Card style={{marginBottom: "24px"}}>
                    <Tabs
                        onChange={(event, newValue) => {
                            setSelectedTab(newValue);
                            if (newValue === 2 || newValue === 1) {
                                setShowFab(false);
                            } else {
                                setShowFab(true);
                            }
                        }}
                        variant="fullWidth"
                        value={selectedTab}>
                        <Tab label="Tur" disabled={initLoading}/>
                        <Tab label="Tilmeldinger" disabled={initLoading}/>
                        <Tab label="Andre indstillinger"
                             disabled={initLoading || (event && event.deletedAt && event.deletedAt > 0)}/>
                    </Tabs>
                </Card>
            </Container>

            {(event && event.deletedAt && event.deletedAt > 0) && (
                <Container style={{marginBottom: "24px"}}>
                    <Alert severity="warning">
                        <AlertTitle>Denne tur er aflyst</AlertTitle>
                        Selvom turen er aflyst kan du dog stadig rette i den.
                    </Alert>
                </Container>
            )}

            {(selectedTab === 0) && (
                <EventForm
                    loading={initLoading}
                    language={selectedLanguage}
                    event={event as TripEvent}
                    onChange={(event: TripEvent) => {
                        setEvent(event);
                    }}/>
            )}

            {selectedTab === 1 && (
                <Container>
                    <Card style={{marginBottom: "24px"}}>
                        <CardContent>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12}>
                                    {(bookings.length > 0) && (
                                        <Typography>
                                            Der er <b>{totalBookedSeats.current} tilmeldte ud af
                                            maksimalt {event?.seats}</b> til denne tur. Der
                                            er {event?.seatsAvailable} pladser tilbage. Ønsker du
                                            at tilføje flere kan du bruge knapperne herunder. Du kan også redigere og
                                            slette eksisterende
                                            tilmeldinger eller printe en oversigt over de tilmeldte.
                                            Står en persons navn med <b>fed skrift</b> er det gruppens kontaktperson.
                                        </Typography>
                                    )}
                                    {(bookings.length === 0) && (
                                        <Typography>
                                            Der er ingen tilmeldte endnu. Brug knapperne herunder for at tilføje
                                            tilmeldinger til turen.
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item={true}>
                                    <Button
                                        onClick={() => {
                                            setShowAddBookingDialog(true);
                                        }}
                                        variant={"contained"} color={"primary"}>Tilføj tilmeldinger</Button>
                                </Grid>

                                <Grid item={true} style={{marginLeft: "auto"}}>
                                    <Button
                                        onClick={() => {
                                            void getPdf((event as any).ID);
                                        }}
                                        color={"info"}
                                        variant="outlined"
                                        startIcon={<PrintIcon/>}>
                                        Print oversigt
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container={true} spacing={2}>
                        {sortedBookings && Object.keys(sortedBookings).map((bookingParent, index) => {
                            return (
                                <Grid item={true} xs={12} key={index}>
                                    <Box sx={{width: '100%'}}>
                                        <Paper sx={{width: '100%', mb: 2}}>
                                            <EnhancedTableToolbar title={`Gruppe ${index + 1}`}/>
                                            <TableContainer sx={{maxHeight: 440}}>
                                                <Table stickyHeader aria-label="sticky table" size={"small"}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Fornavn
                                                            </TableCell>
                                                            <TableCell>
                                                                Efternavn
                                                            </TableCell>
                                                            <TableCell>
                                                                Telefonnummer
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Sprog
                                                            </TableCell>
                                                            <TableCell>
                                                                Pladser
                                                            </TableCell>
                                                            <TableCell>
                                                                Betalt beløb/DKK
                                                            </TableCell>
                                                            <TableCell>
                                                                Note
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {sortedBookings[bookingParent].map((booking: Booking, key: number) => {
                                                            return (
                                                                <TableRow
                                                                    style={{cursor: "pointer"}}
                                                                    onClick={() => {
                                                                        setEditBooking(booking);
                                                                    }}
                                                                    className={getPaidStatusClass(booking)}
                                                                    hover={true}
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={key}>
                                                                    <TableCell>
                                                                        {booking.contactManager ?
                                                                            <b>{booking.firstName}</b> : booking.firstName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.contactManager ?
                                                                            <b>{booking.lastName}</b> : booking.lastName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.phone}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.language}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.seats}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.paidAmount / 100}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.note && booking.note.trim().length > 0 ? "Note (1)" : "-"}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            )}

            {/*// TODO: add checkbox with mail */}
            {selectedTab === 2 && (
                <Container>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        På denne side kan du aflyse turen. Når du aflyser turen modtager alle tilmeldte
                                        en mail
                                        med at turen er aflyst. Handlingen kan, på nuværende tidspunkt, ikke
                                        fortrydes.<br/>
                                        <FormControlLabel control={<Checkbox onChange={(event) => {
                                            setDangerCheckboxChecked(!dangerCheckboxChecked)
                                        }} checked={dangerCheckboxChecked}/>}
                                                          label="Jeg er indforstået med mine handlinger!"/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={deleteCurrentEvent} disabled={!dangerCheckboxChecked}
                                            variant={"contained"}
                                            color={"error"}>Aflys tur</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            )}

            {showFab && (
                <Box sx={{position: 'absolute', bottom: "48px", right: "48px"}}>
                    <Fab
                        disabled={loading || initLoading}
                        onClick={submitForm}
                        variant="extended"
                        color="success"
                        aria-label="add">
                        <SaveIcon sx={{mr: 1}}/>
                        Gem ændringerne
                    </Fab>
                </Box>
            )}

            <AddBookingDialog
                loading={isAddingBookings}
                event={event}
                eventId={id ? id : ""}
                show={showAddBookingDialog}
                onApply={async (booking: Booking) => {
                    await addNewBooking(booking)
                    setShowAddBookingDialog(false);
                }}
                onCancel={() => {
                    setShowAddBookingDialog(false);
                }}/>

            <EditBookingDialog
                deleteLoading={deleteLoading}
                editLoading={editLoading}
                booking={editBooking}
                show={editBooking !== null && editBooking !== undefined}
                onDelete={(booking: Booking) => {
                    setDeleteLoading(true);
                    deleteBooking(booking).then(response => {
                        getAllBookings();
                        setDeleteLoading(false);
                        setEditBooking(null);
                        getCurrentEvent();

                        setShowSnackbar(true);
                        setSnackbarText("Tilmeldingen blev slettet")
                        setTimeout(() => {
                            setShowSnackbar(false);
                        }, 3000)
                    })
                }}
                onApply={(booking: Booking) => {
                    setEditLoading(true);
                    updateBooking(booking).then(response => {
                        setEditBooking(null);
                        setEditLoading(false);

                        setShowSnackbar(true);
                        setSnackbarText("Ændringerne blev gemt")
                        setTimeout(() => {
                            setShowSnackbar(false);
                        }, 3000)

                        getAllBookings();
                    })
                }}
                onCancel={() => {
                    setEditBooking(null);
                }}/>
        </div>
    );
}