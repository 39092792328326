import * as React from 'react';
import './app.scss';
import {routes} from './routes';
import {BrowserRouter} from "react-router-dom";
import {APIProvider} from '../../contexts';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {Settings} from "luxon";

export const App = (): React.ReactElement => {
  Settings.defaultLocale = "da";

  const theme = createTheme({
    palette: {
      primary: {
        main: '#046e65',
      },
      secondary: {
        main: '#1e272c',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <APIProvider>
        <BrowserRouter>
          <CssBaseline/>

          {routes}
        </BrowserRouter>
      </APIProvider>
    </ThemeProvider>
  );
};
