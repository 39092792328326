import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IAddBookingDialogTypes} from "./IAddBookingDialogTypes";
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {Booking} from "../../models/Booking";
import {v4 as uuidv4} from 'uuid';
import {green} from "@mui/material/colors";

export const AddBookingDialog = (props: IAddBookingDialogTypes) => {
    const defaultBooking: Booking = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        contactManager: true,
        eventId: -1,
        bookingId: -1,
        seats: 1
    }

    const [booking, setBooking] = React.useState<Booking>(defaultBooking);
    const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
    const [lastNameError, setLastNameError] = React.useState<boolean>(false);
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [phoneError, setPhoneError] = React.useState<boolean>(false);
    const [seatsError, setSeatsError] = React.useState<boolean>(false);

    const resetStates = () => {
        setBooking(defaultBooking);
        setFirstNameError(false);
    }

    const isFormValid = (): boolean => {
        let firstNameValid: boolean = true;
        let lastNameValid: boolean = true;
        let emailValid: boolean = true;
        let phoneValid: boolean = true;
        let seatsValid: boolean = true;

        if (!booking.firstName || booking.firstName.length === 0) {
            firstNameValid = false;
        }

        if (!booking.lastName || booking.lastName.length === 0) {
            lastNameValid = false;
        }

        if (!booking.email || booking.email.length === 0) {
            emailValid = false;
        }

        if (!booking.phone || booking.phone.length === 0) {
            phoneValid = false;
        }

        if (!booking.seats || booking.seats < 0 || ( props.event && booking.seats > props.event.seatsAvailable)) {
            seatsValid = false;
        }

        return firstNameValid && lastNameValid && emailValid && phoneValid && seatsValid;
    }

    const validateFirstName = (firstName: string): void => {
        let firstNameValid: boolean = false;
        if (firstName && firstName.length > 0) {
            firstNameValid = true;
        }

        setFirstNameError(!firstNameValid);
    }

    const validateLastName = (lastName: string): void => {
        let lastNameValid: boolean = false;
        if (lastName && lastName.length > 0) {
            lastNameValid = true;
        }

        setLastNameError(!lastNameValid);
    }

    const validateEmail = (email: string): void => {
        let emailValid: boolean = false;
        if (email && email.length > 0) {
            emailValid = true;
        }

        setEmailError(!emailValid);
    }

    const validatePhone = (phone: string): void => {
        let phoneValid: boolean = false;
        if (phone && phone.length > 0) {
            phoneValid = true;
        }

        setPhoneError(!phoneValid);
    }

    const validateSeats = (seats: number): void => {
        let seatsValid: boolean = false;
        if (seats > 0 && props.event && seats <= props.event.seatsAvailable) {
            seatsValid = true;
        }

        setSeatsError(!seatsValid);
    }

    const updateBooking = (booking: Booking, prop: string, value: string | number): void => {
        const tempBookings = {...booking};
        tempBookings[prop] = value;
        setBooking(tempBookings)
    }

    return (
        <Dialog
            open={props.show}
            onClose={() => {
                props.onCancel();
                resetStates();
            }}
            maxWidth={"sm"}>
            <DialogTitle>Tilføj tilmeldinger</DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                        <DialogContentText>
                            <Typography>
                                Her kan du tilføje tilmeldinger til turen.
                            </Typography>
                        </DialogContentText>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.firstName}
                                    onChange={(event) => {
                                        validateFirstName(event.target.value);
                                        updateBooking(booking, "firstName", event.target.value)
                                    }}
                                    error={firstNameError}
                                    autoFocus
                                    margin="dense"
                                    label="Fornavn"
                                    helperText={firstNameError ? "Du mangler at udfylde fornavnet" : "Den tilmeldtes fornavn"}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                    disabled={props.loading}
                                    helperText={lastNameError ? "Du mangler at udfylde efternavnet" : "Den tilmeldtes efternavn"}
                                    error={lastNameError}
                                    value={booking.lastName}
                                    onChange={(event) => {
                                        validateLastName(event.target.value)
                                        updateBooking(booking, "lastName", event.target.value)
                                    }}
                                    margin="dense"
                                    label="Efternavn"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.email}
                                    onChange={(event) => {
                                        validateEmail(event.target.value);
                                        updateBooking(booking, "email", event.target.value)
                                    }}
                                    error={emailError}
                                    helperText={emailError ? "Du mangler at skrive en email adresse" : "Den tilmeldtes email adresse"}
                                    margin="dense"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.phone}
                                    onChange={(event) => {
                                        validatePhone(event.target.value);
                                        updateBooking(booking, "phone", event.target.value)
                                    }}
                                    margin="dense"
                                    label="Telefon"
                                    error={phoneError}
                                    helperText={phoneError ? "Du mangler at skrive et telefonnummer" : "Den tilmeldtes telefonnummer, med landekode, e.g. 0045 eller +45"}
                                    type="tel"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item={true} xs={12}>
                                <FormControl
                                    margin="dense"
                                    fullWidth>
                                    <InputLabel>Pladser</InputLabel>

                                    <Select
                                        label="Pladser"
                                        error={seatsError}
                                        disabled={props.loading || (props.event && props.event.seatsAvailable <= 0)}
                                        value={booking.seats}
                                        onChange={(event) => {
                                            validateSeats(Number(event.target.value));
                                            updateBooking(booking, "seats", event.target.value)
                                        }}>
                                        {props.event && Array(props.event.seatsAvailable).fill(0).map((value, index) => (
                                            <MenuItem
                                                key={index + 1}
                                                value={index + 1}>
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {props.event && props.event.seatsAvailable <= 0 && (
                                        <FormHelperText>Der er ikke flere ledige pladser</FormHelperText>
                                    )}
                                    {(props.event && props.event.seatsAvailable > 0) && (
                                        <FormHelperText>{seatsError ? "Der er sket en fejl" : "Antal pladser der skal bookes"}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item={true} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    disabled={props.loading}
                                    fullWidth>
                                    <InputLabel>Sprog</InputLabel>
                                    <Select
                                        value={booking.language}
                                        label="Sprog"
                                        onChange={(event) => {
                                            // validatePhone(event.target.value);
                                            updateBooking(booking, "language", String(event.target.value))
                                        }}>
                                        <MenuItem value={"da-DK"}>Dansk</MenuItem>
                                        <MenuItem value={"en-US"}>Engelsk</MenuItem>
                                        <MenuItem value={"de-DE"}>Tysk</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onCancel();
                    resetStates();
                }} variant={"contained"} color={"primary"}>Aflys</Button>
                <Box sx={{m: 1, position: 'relative'}}>
                    <Button
                        disabled={!isFormValid() || props.loading}
                        variant={"contained"}
                        color={"success"}
                        onClick={() => {
                            if (isFormValid()) {
                                const randomId = uuidv4();
                                const tempBooking = {...booking};
                                tempBooking.eventId = props.eventId;
                                tempBooking.bookingId = `${props.eventId},${randomId}`

                                props.onApply(tempBooking);

                                // TODO: This is annoying
                                resetStates();
                            }
                        }}>Jeg er færdig</Button>
                    {props.loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
}