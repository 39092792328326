export class APIService {
  private static baseUrl = window.location.hostname.includes("localhost") ? "http://localhost:8888/api/v1/" : "https://api.ds-tinker.dk/api/v1/";
  // private static baseUrl = "https://api.ds-tinker.dk/api/v1/";

  private static request<T>(method: "GET" | "POST" | "DELETE" | "PUT", pathname: string, body?: any, headers?: any): Promise<T> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    const authorization = localStorage.getItem("Authorization")
    if(authorization) {
      requestHeaders.set('Authorization', authorization);
    }

    try {
      return fetch(this.baseUrl + pathname, {
        method: method,
        body: JSON.stringify(body),
        headers: requestHeaders
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if(response.headers.get("Content-Type") === "application/pdf") {
              return response.blob() as unknown as T
            } else {
              return response.json() as unknown as T
            }
          } else {
            return new Promise((resolve, reject) => {
              reject(response.status)
            })
          }
        })
    } catch (exception) {
      return new Promise((resolve, reject) => {
        reject(exception)
      })
    }
  }

  public static async get<T>(pathname: string): Promise<T> {
    return this.request<T>("GET", pathname);
  }

  public static async delete<T>(pathname: string): Promise<T> {
    return this.request<T>("DELETE", pathname);
  }

  public static async post<T>(pathname: string, body?: any, headers?: any): Promise<T> {
    return this.request<T>("POST", pathname, body, headers);
  }

  public static async put<T>(pathname: string, body?: any, headers?: any): Promise<T> {
    return this.request<T>("PUT", pathname, body, headers);
  }

}