/* eslint-disable class-methods-use-this */
import { DateTime } from 'luxon';
import BaseAdapterLuxon from '@date-io/luxon';
const formatTokenMap = {
  // Year
  y: 'year',
  yy: 'year',
  yyyy: 'year',
  // Month
  L: 'month',
  LL: 'month',
  LLL: {
    sectionType: 'month',
    contentType: 'letter'
  },
  LLLL: {
    sectionType: 'month',
    contentType: 'letter'
  },
  M: 'month',
  MM: 'month',
  MMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  MMMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  // Day of the month
  d: 'day',
  dd: 'day',
  // Day of the week
  c: 'weekDay',
  ccc: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  cccc: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  ccccc: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  E: 'weekDay',
  EEE: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  EEEE: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  EEEEE: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  // Meridiem
  a: 'meridiem',
  // Hours
  H: 'hours',
  HH: 'hours',
  h: 'hours',
  hh: 'hours',
  // Minutes
  m: 'minutes',
  mm: 'minutes',
  // Seconds
  s: 'seconds',
  ss: 'seconds'
};
export class AdapterLuxon extends BaseAdapterLuxon {
  constructor(...args) {
    super(...args);
    this.isMUIAdapter = true;
    this.formatTokenMap = formatTokenMap;
    this.escapedCharacters = {
      start: "'",
      end: "'"
    };
    this.expandFormat = format => {
      if (!DateTime.expandFormat) {
        throw Error('Your luxon version does not support `expandFormat`. Consider upgrading it to v3.0.2');
      }
      // The returned format can contain `yyyyy` which means year between 4 and 6 digits.
      // This value is supported by luxon parser but not luxon formatter.
      // To avoid conflicts, we replace it by 4 digits which is enough for most use-cases.
      return DateTime.expandFormat(format, {
        locale: this.locale
      }).replace('yyyyy', 'yyyy');
    };
    this.getFormatHelperText = format => {
      return this.expandFormat(format).replace(/(a)/g, '(a|p)m').toLocaleLowerCase();
    };
    this.getWeekNumber = date => {
      return date.weekNumber;
    };
  }
}